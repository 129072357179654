export default function Avatar({ src, className, size = 9, ...props }) {
  const sizeDic = {
    6: 'w-6 h-6',
    7: 'w-7 h-7',
    8: 'w-8 h-8',
    9: 'w-9 h-9',
    12: 'w-12 h-12',
    14: 'w-14 h-14',
    20: 'w-20 h-20',
    24: 'w-24 h-24',
    28: 'w-28 h-28'
  }
  return (
    <C.Image
      src={ src }
      className={ cn('rounded-full', sizeDic[size], className) }
      { ...props }
    />
  )
}
